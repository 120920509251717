
export default {
  name: "Banner",
  data() {
    return {
      flickityOptions: {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
      },
    };
  },
  methods: {
    showPrevNextButtons(show) {
      this.flickityOptions.prevNextButtons = show;
      if (this.$refs.flickity) {
        this.$refs.flickity.$flickity.options.prevNextButtons = show;
      }
    },
  },
};
