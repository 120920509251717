import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=7add1824&scoped=true"
import script from "./ProjectCard.vue?vue&type=script&lang=js"
export * from "./ProjectCard.vue?vue&type=script&lang=js"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=7add1824&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7add1824",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsBuilding: require('/root/elevatorfe/components/Icons/Building.vue').default,IconsWeight: require('/root/elevatorfe/components/Icons/Weight.vue').default,IconsSpeed: require('/root/elevatorfe/components/Icons/Speed.vue').default})
