
export default {
  name: 'VButton',
  props: {
    type: {
      type: String,
      default: 'primary', // ['primary', 'secondary']
    },
    outLine: {
      type: Boolean,
      default: false,
    },
    htmlType: {
      type: String,
      default: 'button', // ['button', 'submit']
    },
    size: {
      type: String,
      default: 'default', // ['mini', 'default', 'large']
    },
    background: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'center',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '45px',
    },
  },
  computed: {
    style() {
      const width = typeof this.width === 'number' ? `${this.width}px` : `${this.width}`
      const height = typeof this.height === 'number' ? `${this.height}px` : `${this.height}`
      return `width: ${width}; height: ${height};`
    },
  },
}
