import { render, staticRenderFns } from "./index.vue?vue&type=template&id=943bff56"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=943bff56&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/root/elevatorfe/components/Banner/Banner.vue').default,Introduce: require('/root/elevatorfe/components/Introduce/Introduce.vue').default,Feature: require('/root/elevatorfe/components/Feature/Feature.vue').default,IconsQuatity: require('/root/elevatorfe/components/Icons/quatity.vue').default,IconsSafe: require('/root/elevatorfe/components/Icons/Safe.vue').default,IconsModern: require('/root/elevatorfe/components/Icons/modern.vue').default,Products: require('/root/elevatorfe/components/Products/Products.vue').default,ProjectCard: require('/root/elevatorfe/components/ProjectCard/ProjectCard.vue').default})
