
export default {
  name: 'IconArrowLeft',
  props: {
    color: {
      type: String,
      default: '#A7AEB8',
    },
  },
}
