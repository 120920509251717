
export default {
  name: 'Feature',
  methods: {
    makeCall() {
      window.location.href = 'tel:0901987666';
    }
  }

}
