
export default {
  name: 'Project',
  data() {
    return {
      data: [
        {
        name: 'Anh Linh – Xã Đàn – Đống Đa – Hà Nội',
        imgs: [require('~/assets/img/project/p11.jpg'),require('~/assets/img/project/p12.jpg')],
        building: '4 tầng',
        weight: '400kg',
        speed: '0.3m/s',
      },

        {
        name: 'Anh Quyền – Khu đô thị Vinhomes Thanh Hóa',
        imgs: [require('~/assets/img/project/p21.jpg'),require('~/assets/img/project/p22.jpg')],
        building: '4 tầng',
        weight: '400kg',
        speed: '0.3m/s',
      },
      {
        name: 'Anh Năm – Khu đô thị Văn Quán – Hà Đông – Hà Nội',
        imgs: [require('~/assets/img/project/p31.jpg'),require('~/assets/img/project/p32.jpg')],
        building: '4 tầng',
        weight: '400kg',
        speed: '0.3m/s',
      },
    ]
    }
  }

}
